export const fetchImportedLanguageCodeList = ({ commit, dispatch}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/imported-language-code-list').then((response) => {
        commit('setImportedLanguageCodeListData', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};
export const fetchMasterLanguageList = ({ commit, dispatch}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/languages-list-profile').then((response) => {
        commit('setMasterLanguageListData', response)
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const postImportedLanguageName = ({ commit, dispatch}, { payload, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/save-imported-language-name', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false;
    })
};

export const fetchLanguageNameSlug = ({ commit}, slug) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-language-name-by-slag/'+slug).then((response) => {
        commit('setLanguageNameBySlug', response)
        return response.data.success;
    }).catch((error) => {
        return false;
    });   
};
export const getLanguageTranslationList = ({ commit, dispatch },query='') => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-language-translation-list'+query).then((response) => {
        commit('setLanguageList', response.data);
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const editTranslation = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/update-language-translation-value',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const generateJSONFile = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/generate-json-file',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const copyJSONFile = ({ commit, dispatch },payload) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/copy-json-file/'+payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const exportFile = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/export-file',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const addTranslationValue = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/add-translation-value',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}